import http from './axios';

class SalesService {
    getLocationInfo(data) {
        return http.get('/sales/locations', {params: data})
    }

    createVisit(data) {
        return http.post('/sales/visits', data);
    }

    getVisits(params) {
        return http.get('/sales/visits', {params: params});
    }

    getVisit(id) {
        return http.get(`/sales/visits/${id}`);
    }

    updateVisit(id, data) {
        return http.patch(`/sales/visits/${id}`, data);
    }

    getStats() {
        return http.get('/sales/stats');
    }

    exportCsv() {
        return http.get('/sales/export');
    }

    createConfigCalculation(data) {
        return http.post('/sales/configs', data);
    }

    getConfigCalculations(params) {
        return http.get('/sales/configs', {params : params});
    }

    showConfigCalculation(id) {
        return http.get(`/sales/configs/${id}`);
    }

    updateConfigCalculation(id, data) {
        return http.patch(`/sales/configs/${id}`, data);
    }
}

export default new SalesService();