<template>
  <span
    :style="{
      height,
      width: width,
      minWidth: minWidth,
      maxWidth: maxWidth,
    }"
    class="skeleton"
    :class="{ 'is-animating': animating }"
  />
</template>

<script>
export default {
  props: {
    maxWidth: {
      default: "100%",
      type: String,
    },
    minWidth: {
      default: null,
      type: String,
    },
    height: {
      default: "1.5em",
      type: String,
    },
    width: {
      default: null,
      type: String,
    },
    animating: Boolean,
  },
};
</script>

<style lang="scss">
.skeleton {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 5px;
  background-color: var(--light-gray);

  &.is-animating::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
