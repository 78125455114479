import { createApp } from 'vue';
import mitt from 'mitt';
import VueCookies from 'vue-cookies';
// import VueGtag from 'vue-gtag';

import App from './App.vue';

import AdminService from './services/admin';
import ApiService from './services/api';
import AuthService from './services/auth';
import CustomerService from './services/customer';
import GroupService from './services/group';
import InviteService from './services/invite';
import InvoiceService from './services/invoice';
import LocationService from './services/location';
import PortalService from './services/portal';
import NetworkService from './services/network';
import UserService from './services/user';
import PaymentService from './services/payment';
import WebhookService from './services/webhookservice';
import ProductService from './services/product';
import DashboardService from './services/dashboard';
import TenantService from './services/tenant';
import PartnerService from './services/partner';
import ReferralService from './services/referral';
import SalesService from './services/sales';
import IntegrationService from './services/integration';
import InventoryService from './services/inventory';
import SupplierService from './services/supplier';
import ControllerService from './services/controller';

import { globalMixin } from './mixins/globalMixin.js';
import Vue3Lottie from 'vue3-lottie';

import Router from './router';
import store from './store';
import i18n from './i18n';
import Toast, { TYPE, useToast } from 'vue-toastification';

import BasicSkeleton from './components/ui/BasicSkeleton';
import CardSmall from './components/ui/CardSmall.vue';
import ValidationBox from './components/ValidationBox';
import InputBox from './components/form/InputBox';
import SplitContainer from './components/ui/splitContainer';
import BasicLoader from './components/ui/BasicLoader';
import BasicNotification from './components/ui/BasicNotification';
import BasicTooltip from './components/ui/BasicTooltip';
import BasicErrorNotification from './components/ui/BasicErrorNotification';

import ClipBoard from './components/ui/ClipBoard';
import ContentContainer from './components/ui/ContentContainer';
import CheckBox from './components/form/CheckBox';
import SelectBox from './components/form/SelectBox';
import BasicButton from './components/ui/BasicButton';
import BreadCrumbs from './components/ui/BreadCrumbs';
import BasicLoading from './components/ui/BasicLoading';
import SubmitButton from './components/buttons/SubmitButton';
import EditButton from './components/buttons/EditButton';
import ViewButton from './components/buttons/ViewButton';
import DeleteButton from './components/buttons/DeleteButton';
import AddButton from './components/buttons/AddButton';
import CancelButton from './components/buttons/CancelButton';
import moment from 'moment';
import VueGoogleMaps from '@fawmi/vue-google-maps';
/* Import icons from font-awesome */
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fad } from '@fortawesome/pro-duotone-svg-icons';

import {
  faWindows,
  faApple,
  faAndroid,
  faStripe,
  faFacebook,
  faGoogle,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fad);
library.add(faWindows);
library.add(faApple);
library.add(faAndroid);
library.add(faStripe);
library.add(faFacebook);
library.add(faGoogle);
library.add(faLinkedin);

dom.watch();

require('@/assets/scss/main.scss');

const emitter = mitt();
const app = createApp(App);
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('ClipBoard', ClipBoard);
app.component('BasicSkeleton', BasicSkeleton);
app.component('ValidationBox', ValidationBox);
app.component('InputBox', InputBox);
app.component('SplitContainer', SplitContainer);
app.component('ContentContainer', ContentContainer);
app.component('CardSmall', CardSmall);
app.component('BasicLoader', BasicLoader);
app.component('BasicButton', BasicButton);
app.component('SubmitButton', SubmitButton);
app.component('EditButton', EditButton);
app.component('ViewButton', ViewButton);
app.component('DeleteButton', DeleteButton);
app.component('AddButton', AddButton);
app.component('CancelButton', CancelButton);
app.component('CheckBox', CheckBox);
app.component('SelectBox', SelectBox);
app.component('BasicNotification', BasicNotification);
app.component('BasicTooltip', BasicTooltip);
app.component('BreadCrumbs', BreadCrumbs);
app.component('BasicLoading', BasicLoading);
app.component('BasicErrorNotification', BasicErrorNotification);
app.directive('click-outside', {
  beforeMount(el, binding, vnode) {
    el.eventSetDrag = function () {
      el.setAttribute('data-dragging', 'yes');
    };
    el.eventClearDrag = function () {
      el.removeAttribute('data-dragging');
    };
    el.eventOnClick = function (event) {
      var dragging = el.getAttribute('data-dragging');
      if (!(el == event.target || el.contains(event.target)) && !dragging) {
        binding.value(event);
      }
    };
    document.addEventListener('touchstart', el.eventClearDrag);
    document.addEventListener('touchmove', el.eventSetDrag);
    document.addEventListener('click', el.eventOnClick);
    document.addEventListener('touchend', el.eventOnClick);
  },
  unmounted(el) {
    document.removeEventListener('touchstart', el.eventClearDrag);
    document.removeEventListener('touchmove', el.eventSetDrag);
    document.removeEventListener('click', el.eventOnClick);
    document.removeEventListener('touchend', el.eventOnClick);
    el.removeAttribute('data-dragging');
  },
});

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBoNZa2r2jBWZK5PnqX5bKuT9OCoRXfbcs',
  },
});
app.use(moment);
app.use(i18n);
app.use(Router);
app.use(store);
app.use(VueCookies, { expires: '365d' });
app.use(Vue3Lottie);
// if (process.env.NODE_ENV === 'production') {
//   app.use(
//     VueGtag,
//     {
//       appName: 'amplr.cloud',
//       pageTrackerScreenviewEnabled: true,
//       config: { id: 'G-YEFL3TWHK7' },
//     },
//     Router
//   );
// }

app.use(Toast, {
  position: 'bottom-right',
  closeOnClick: true,
  timeout: 5000,
  hideProgressBar: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      return false;
    }
    return toast;
  },
  toastDefaults: {
    [TYPE.ERROR]: {
      icon: 'fad fa-circle-exclamation',
    },
    [TYPE.SUCCESS]: {
      icon: 'fad fa-circle-check',
    },
    [TYPE.WARNING]: {
      icon: 'fad fa-circle-exclamation',
    },
    [TYPE.INFO]: {
      icon: 'fad fa-circle-info',
    },
  },
});

app.config.globalProperties.AdminService = AdminService;
app.config.globalProperties.ApiService = ApiService;
app.config.globalProperties.AuthService = AuthService;
app.config.globalProperties.CustomerService = CustomerService;
app.config.globalProperties.GroupService = GroupService;
app.config.globalProperties.InviteService = InviteService;
app.config.globalProperties.InvoiceService = InvoiceService;
app.config.globalProperties.LocationService = LocationService;
app.config.globalProperties.PortalService = PortalService;
app.config.globalProperties.NetworkService = NetworkService;
app.config.globalProperties.UserService = UserService;
app.config.globalProperties.PaymentService = PaymentService;
app.config.globalProperties.WebhookService = WebhookService;
app.config.globalProperties.ProductService = ProductService;
app.config.globalProperties.DashboardService = DashboardService;
app.config.globalProperties.TenantService = TenantService;
app.config.globalProperties.PartnerService = PartnerService;
app.config.globalProperties.ReferralService = ReferralService;
app.config.globalProperties.SalesService = SalesService;
app.config.globalProperties.IntegrationService = IntegrationService;
app.config.globalProperties.InventoryService = InventoryService;
app.config.globalProperties.SupplierService = SupplierService;
app.config.globalProperties.ControllerService = ControllerService;

app.config.globalProperties.$moment = moment;
app.config.globalProperties.$toast = useToast();

app.config.globalProperties.$eventBus = emitter;
app.config.globalProperties.$isProduction =
  process.env.NODE_ENV === 'production';
app.mixin(globalMixin);

function lightenColor(hex, factor) {
  const color = hex.replace('#', '');
  const r = Math.round(
    (1 - factor) * parseInt(color.substring(0, 2), 16) + factor * 255
  );
  const g = Math.round(
    (1 - factor) * parseInt(color.substring(2, 4), 16) + factor * 255
  );
  const b = Math.round(
    (1 - factor) * parseInt(color.substring(4, 6), 16) + factor * 255
  );
  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

store.dispatch('fetchBrandConfig').then((brandConfig) => {
  if (brandConfig) {
    // Apply the brand configuration globally (CSS variables) only if values exist
    if (brandConfig.main_color) {
      document.documentElement.style.setProperty(
        '--primary-blue',
        brandConfig.main_color
      );
      const invertedColor = lightenColor(brandConfig.main_color, 0.8);
      document.documentElement.style.setProperty(
        '--primary-blue-inverted',
        invertedColor
      );
      document.documentElement.style.setProperty(
        '--test-color',
        brandConfig.main_color
      );
    }

    // Set favicon if the icon path exists
    if (brandConfig.icon_path) {
      let iconUrl = brandConfig.icon_path;
      let favicon = document.querySelector('link[rel="icon"]');
      if (!favicon) {
        // If there's no favicon tag, create one
        favicon = document.createElement('link');
        favicon.setAttribute('rel', 'icon');
        document.head.appendChild(favicon);
      }
      // Set the href attribute to the new favicon URL
      favicon.setAttribute('href', iconUrl);
    }

    // Add any other checks for specific properties as needed
    // e.g., if (brandConfig.other_property) { ... }
  }
});

app.mount('#app');
