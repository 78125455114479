import http from './axios';

class WebhookService {
  getLocationWebhookLog(id) {
    return http.get(`/locations/${id}/webhooks`);
  }

  testWebhook(id, payload) {
    return http.post(`/locations/${id}/webhooks/test`, payload);
  }
}
export default new WebhookService();
