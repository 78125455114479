<template>
  <div
    class="notification is-flex is-flex-direction-row is-align-items-center mb-0 has-border no-color"
    :class="[
      {
        'is-light': light,
      },
      color ? `is-${color}` : '',
      rounded ? '' : 'is-border-radius-none',
    ]"
  >
    <font-awesome-icon
      v-if="icon"
      :icon="['fad', icon]"
      :iconSpecial="iconSpecial"
      class="is-flex ml-2 mr-5 is-size-1"
      :class="iconSmall ? 'is-size-4' : 'is-size-1'"
      :spin="iconSpin"
    />
    <font-awesome-icon
      v-if="iconSpecial"
      :icon="iconSpecial"
      :iconSpecial="iconSpecial"
      class="is-flex ml-2 mr-5 is-size-1"
      :class="iconSmall ? 'is-size-4' : 'is-size-1'"
      :spin="iconSpin"
    />

    <div class="is-flex-grow-1">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      default: '',
      type: String,
    },
    color: {
      default: '',
      type: String,
    },
    light: {
      default: false,
      type: Boolean,
    },
    iconSpin: {
      default: false,
      type: Boolean,
    },

    rounded: {
      default: true,
      type: Boolean,
    },
    iconSpecial: {
      type: Array,
      default: () => null,
    },
    iconSmall: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
