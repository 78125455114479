<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      payload: {
        email: '',
        password: '',
      },
    };
  },
  watch: {
    '$store.state.user.lang': {
      handler() {
        this.$i18n.locale = this.$store.state.user.lang;
      },
      immediate: true,
    },
  },

  mounted() {
    if (this.$store.state.user.lang != null) {
      this.$i18n.locale = this.$store.state.user.lang;
    }
  },
};
</script>
<style lang="scss">
.my-notification {
  margin: 10px 24px 0 0;
  padding: 10px;
  font-size: 14px;
  color: #ffffff;
  animation: popIn;
  animation-duration: 4.5s;
  border-radius: 1em;

  &.success {
    background-color: #48c78e;
  }

  &.warn {
    background-color: #ffe08a;
  }

  &.error {
    background-color: #f14668;
  }

  &.info {
    background-color: #d8e4ff;
    color: #175dff;
  }

  @keyframes popIn {
    0% {
      margin-top: -5rem;
    }
    20% {
      margin-top: 10px;
    }
    80% {
      margin-top: 10px;
    }
    100% {
      margin-top: -5rem;
    }
  }
}
</style>
