import axios from 'axios';
import store from '../store';
import router from '@/router';
import i18n from '@/i18n';
import { useToast } from 'vue-toastification';

const { t } = i18n.global;
const $toast = useToast();

axios.defaults.withCredentials = true;

const httpworker = axios.create({
  baseURL: '/rest',

  headers: {
    'Content-type': 'application/json',
  },
});

httpworker.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

httpworker.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 423) {
      store.commit('SHOW_PASSWORD_CONFIRM_MODAL');
      return Promise.reject(error);
    }

    if (error.response.status === 403) {
      router.push('/no-access');
      return Promise.reject(error);
    }

    if (error.response.status === 401 || error.response.status === 419) {
      router.go('/auth');
      localStorage.clear();
      store.commit('LOGOUT');

      if (!router.currentRoute._value.path.includes('/auth')) {
        $toast.info(t('error.sessionExpired'));
      }

      return Promise.reject(error);
    }

    if (error.response.status === 500) {
      $toast.info(t('error.generalError'));
      return Promise.reject(error);
    }

    if (error.response.status === 404) {
      $toast.info(t('error.404'));
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default httpworker;
