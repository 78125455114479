<template>
  <BasicButton
    color="success"
    icon="plus"
    :size="small ? 'small' : 'normal'"
  >
    <slot />
  </BasicButton>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
