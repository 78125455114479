<template>
  <BasicButton
    v-if="!hideText"
    color="primary"
    light
    size="small"
    icon="eye"
  >
    {{ $t('button.view') }}
  </BasicButton>
  <BasicButton
    v-else
    color="primary"
    light
    size="small"
    icon="eye"
  />
</template>
<script>
export default {
  props: {
    hideText: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
