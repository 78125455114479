<template>
  <div
    class="field"
    :class="{ 'is-fullwidth': fullwidth }"
  >
    <label
      v-if="label"
      class="label mb-3"
    >
      <span>{{ label }}</span>
      <sup
        v-if="required"
        class="has-text-danger"
      >*</sup>
    </label>
    <div class="control has-icons-left">
      <span class="icon is-small is-left">
        <font-awesome-icon :icon="['fad', 'list']" />
      </span>
      <div
        class="select"
        :class="{ 'is-fullwidth': fullwidth, 'is-danger': errors?.length }"
      >
        <select
          v-model="model"
          :disabled="disabled"
        >
          <option
            v-if="placeholder"
            value=""
            disabled
            hidden
          >
            {{ placeholder }}
          </option>
          <option
            v-for="option in options"
            :key="option.key"
            :value="option.value"
            :selected="model == option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div
    v-for="error of errors"
    :key="error.$uid"
  >
    <div class="notification is-danger mb-3">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    required: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    label: {
      default: '',
      type: String,
    },
    modelValue: {
      default: '',
      type: [String, Number],
    },
    placeholder: {
      default: '',
      type: String,
    },
    fullwidth: {
      default: false,
      type: Boolean,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue || typeof this.modelValue === 'number'
          ? this.modelValue.toString()
          : '';
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style>
.field.is-fullwidth {
  width: 100%;
}
</style>
