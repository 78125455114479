<template>
  <BasicButton
    type="submit"
    color="primary"
    icon="check"
  >
    <template v-if="$slots.default">
      <slot />
    </template>
    <template v-else>
      {{ $t('button.submit') }}
    </template>
  </BasicButton>
</template>
