import http from './axios';

class ControllerService {
  getControllers() {
    return http.get('/controllers');
  }

  getInfo(id) {
    return http.get(`/controllers/${id}`);
  }
  getSites(id) {
    return http.get(`/controllers/${id}/listsites`);
  }
}

export default new ControllerService();
