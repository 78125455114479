import http from './axios';

class PartnerService {
  getPartners() {
    return http.get('/partners');
  }

  getPartner(id) {
    return http.get(`/partners/${id}`);
  }

  getPartnerUsers(id) {
    return http.get(`/partners/${id}/users`);
  }

  getPartnerInvites(id) {
    return http.get(`/partners/${id}/invites`);
  }

  getSelf() {
    return http.get(`/partners/self`);
  }

  getPartnersNames() {
    return http.get(`/partners/names`);
  }

  createPartner(payload) {
    return http.post('/partners', payload);
  }

  updatePartner(payload) {
    return http.put('/partners', payload);
  }

  deletePartner(payload) {
    return http.delete('/partners/' + payload);
  }

  inviteUser(partner_id, payload) {
    return http.post(`/partners/link/${partner_id}`, payload);
  }

  deleteInvite(link_id) {
    return http.delete(`/partners/link/${link_id}`);
  }

  deleteUser(partner_id, user_id) {
    return http.delete(`/partners/user/${partner_id}/${user_id}`);
  }

  updatePartnerLocations(partner_id, payload) {
    return http.post(`/partners/locations/${partner_id}`, payload);
  }

  updatePartnerCustomers(partner_id, payload) {
    return http.post(`/partners/customers/${partner_id}`, payload);
  }

  updatePartnerGroups(partner_id, payload) {
    return http.post(`/partners/groups/${partner_id}`, payload);
  }

  deleteLocation(partner_id, location_id) {
    return http.delete(`/locations/${location_id}/partner/${partner_id}`);
  }

  deleteCustomer(partner_id, customer_id) {
    return http.delete(`/customers/${customer_id}/partner/${partner_id}`);
  }

  deleteGroup(partner_id, group_id) {
    return http.delete(`/groups/${group_id}/partner/${partner_id}`);
  }

  updateLogo(id, payload) {
    return http.post(`/partners/${id}/logo`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new PartnerService();
