import http from './axios';

class InviteService {
  getInvites() {
    return http.get('/invites');
  }
  resendUserInvite(id) {
    return http.post(`/invites/resend/${id}`);
  }
  deleteUserInvite(id) {
    return http.delete(`/invites/${id}`);
  }
  createInvite(payload) {
    return http.post('/invites', payload);
  }
  validateEmail(email) {
    return http.post('/invites/validate', { email });
  }
  getInvitesGroup(id) {
    return http.get(`/invites/group/${id}`);
  }
  getInvitesCustomer(id) {
    return http.get(`/invites/customer/${id}`);
  }
  getInvitesLocations(id) {
    return http.get(`/invites/location/${id}`);
  }
}

export default new InviteService();
