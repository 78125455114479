<template>
  <label class="checkbox is-flex is-align-items-center">
    <input
      v-model="model"
      type="checkbox"
      :disabled="disabled"
      :name="name"
      :value="value"
      class="mr-2"
    />
    <span :class="smallText ? 'is-size-7' : ''">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  props: {
    smallText: {
      default: false,
      type: [Boolean, Number],
    },
    disabled: {
      default: false,
      type: [Boolean, Number],
    },
    modelValue: {
      default: false,
      type: [Boolean, Number, Array],
    },
    value: {
      default: false,
      type: [Boolean, Object],
    },
    name: {
      default: '',
      type: String,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
