<template>
  <div>
    <div
      class="m-auto loader loading-spinner"
      style="width: 1.5rem; height: 1.5rem"
    />
    <div
      v-if="text"
      class="mt-3 is-flex is-justify-content-center"
    >
      {{ text }}
    </div>
    <div
      v-if="message && showMessage"
      class="notification is-warning mt-4"
    >
      <p class="is-size-6 has-text-centered">
        {{ $t('error.messageLoadingLong') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    showMessage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: false,
    };
  },
  mounted() {
    setInterval(() => {
      this.message = true;
    }, 5000);
  },
};
</script>

<style lang="scss">
.loading-spinner {
  border-top: 2px solid var(--primary-blue) !important;
  border-right-color: transparent;
  border-top-color: transparent;
}
</style>
