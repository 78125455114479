<template>
  <button
    class="button"
    :type="type"
    :disabled="disabled"
    :class="[
      `is-${size}`,
      {
        'is-light': light,
        'is-fullwidth': fullwidth,
        'is-outlined': outlined,
        'is-inverted': inverted,
        'is-rounded': rounded,
        'is-text': text,
      },
      color ? `is-${color}` : '',
    ]"
  >
    <span
      v-if="icon"
      class="icon"
    >
      <font-awesome-icon :icon="['fad', icon]" />
    </span>
    <span v-if="$slots.default" class="is-flex is-align-items-center"><slot /></span>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'normal',
    },
    color: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    text: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
