import http from './axios';

class UserService {
  getUser() {
    return http.get('/self');
  }

  getUserDetails(id) {
    return http.get(`/users/${id}`);
  }

  getUserData() {
    return http.get('/self/data');
  }
  updateUser(payload) {
    return http.put('/self', payload);
  }

  updateUserPassword(data) {
    return http.put('/self/password', data);
  }

  getUsers() {
    return http.get('/users');
  }

  updateUsers(id, data) {
    return http.put(`/users/${id}`, data);
  }

  deleteUsers(id, data) {
    return http.delete(`/users/${id}`, { data });
  }

  getProfile() {
    return http.get('/users/me');
  }

  getTokens(params) {
    return http.get('/users/me/tokens', {params});
  }
  createToken(payload) {
    return http.post('/users/me/tokens', payload);
  }
  deleteToken(id) {
    return http.delete(`/users/me/tokens/${id}`);
  }
}

export default new UserService();
