import http from './axios';

class CustomerService {
  getCustomers(params) {
    return http.get(`/customers`, { params });
  }

  createCustomer(payload) {
    return http.post('/customers', payload);
  }

  getCustomer(customer_id) {
    return http.get(`/customers/${customer_id}`);
  }

  deleteCustomer(customer_id) {
    return http.delete(`/customers/${customer_id}`);
  }

  updateCustomerStripe(customer_id, payload) {
    return http.put(`/customers/${customer_id}/stripe`, payload);
  }

  getCustomerLocations(customer_id) {
    return http.get(`/customers/${customer_id}/locations`);
  }

  updateCustomerLocations(customer_id, payload) {
    return http.put(`/customers/${customer_id}/locations`, payload);
  }

  createCustomerLocations(customer_id, payload) {
    return http.post(`/customers/${customer_id}/locations`, payload);
  }

  getCustomerInvoices(customer_id, params) {
    return http.get(`/customers/${customer_id}/invoices`, {params: params});
  }

  createDraftInvoice(customer_id, payload) {
    return http.post(`/customers/${customer_id}/invoices`, payload);
  }

  getCustomerBillingPortal(customer_id) {
    return http.get(`/customers/${customer_id}/payments/portal`);
  }

  updateCustomer(customer_id, payload) {
    return http.put(`/customers/${customer_id}`, payload);
  }

  updateCustomerLogo(customer_id, payload) {
    return http.post(`/customers/${customer_id}/logo`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getLocationOpeninghours(id) {
    return http.get(`/customers/${id}/openinghours`);
  }

  updateLocationOpeninghours(id, data) {
    return http.put(`/customers/${id}/openinghours`, data);
  }

  getPartners(id) {
    return http.get(`/customers/${id}/partner`);
  }

  addPartner(customer_id, partner_id) {
    return http.post(`/customers/${customer_id}/partner/${partner_id}`);
  }

  deletePartner(customer_id, partner_id) {
    return http.delete(`/customers/${customer_id}/partner/${partner_id}`);
  }

  deleteBranding(customerId) {
    return http.delete(`/customers/${customerId}/branding`)
  }

  showCustomerBranding(customerId) {
    return http.get(`/customers/${customerId}/branding`)
  }
  
  createCustomerBranding(customerId, formData) {
    return http.post(`/customers/${customerId}/branding`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  updateCustomerBrandingImages(customerId, formData, headers) {
    return http.post(`/customers/${customerId}/branding`, formData, headers)
  }

  updateCustomerBranding(customerId, formData) {
    return http.patch(`/customers/${customerId}/branding`, formData)
  }
}

export default new CustomerService();
