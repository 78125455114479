<template>
  <div class="is-flex is-flex-direction-row">
    <font-awesome-icon
      :icon="['fad', 'spinner-third']"
      spin
      class="icon has-text-primary"
    />
    <p class="ml-2 has-text-primary">{{ $t('loading') }}</p>
  </div>
</template>
