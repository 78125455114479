<template>
  <div
    :class="isHover ? 'tag is-link is-light is-clickable' : 'tag'"
    @mouseover="isHover = true"
    @mouseout="isHover = false"
    @click="copy"
  >
    <span class="">{{ label ?? value }}</span
    ><font-awesome-icon class="ml-2" :icon="['fad', 'clipboard']" />
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },

    label: {
      type: String,
    },
  },

  data() {
    return {
      isHover: false,
    };
  },

  methods: {
    copy() {
      navigator.clipboard.writeText(this.value).then(() => {
        this.$toast.success(this.$t('success.user.copy'));
      });
    },
  },
};
</script>
