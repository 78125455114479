import http from './axios';

class AdminService {
  getDashboardStats() {
    return http.get('/admin/dashboard');
  }

  getCloudControllers() {
    return http.get('/admin/cloud');
  }

  getControllers() {
    return http.get('/controllers');
  }

  getPeplinkOrg(id) {
    return http.get(`/controllers/${id}/org`);
  }
  getPeplinkGroup(id, orgId) {
    return http.get(`/controllers/${id}/org/${orgId}`);
  }

  getControllerSites(id) {
    return http.get(`/controllers/${id}/sites`);
  }

  getZerotierNodes(id) {
    return http.get(`/zerotier/network/members`);
  }

  updateZerotierNode(id, payload) {
    return http.patch(`/zerotier/network/members/${id}`, payload);
  }

  getPpskProfiles(id, siteId) {
    return http.get(`/controllers/${id}/sites/${siteId}/ppsk`);
  }

  createController(payload) {
    return http.post('/controllers', payload);
  }

  showController(id) {
    return http.get(`/controllers/${id}`);
  }

  getFinancials() {
    return http.get('/admin/financials');
  }

  startImposterMode(payload) {
    return http.post('/impersonate', payload);
  }

  stopImpersonating() {
    return http.get('/impersonate');
  }
}

export default new AdminService();
