<template>
  <div class="box h-100 has-text-centered">
    <BasicSkeleton
      v-if="loading"
      width="46px"
      height="33px"
      :animating="loading && hasAccess"
    />
    <div v-else class="card-small">
      <slot />
    </div>

    <h5 class="is-size-5 has-text-weight-bold mt-1">
      <BasicSkeleton
        v-if="loading"
        max-width="150px"
        width="100%"
        :animating="loading && hasAccess"
      />
      <div v-else>
        <span class="mr-2">{{ title }}</span
        ><BasicTooltip v-if="tooltip" :content="tooltip" />
      </div>
    </h5>
    <BasicSkeleton
      v-if="loading && $slots.subdata"
      width="40px"
      height="24px"
      class="mt-1"
      :animating="loading && hasAccess"
    />
    <div
      v-else-if="$slots.subdata"
      class="tag is-rounded is-link is-light mt-1"
    >
      <slot name="subdata" />
    </div>
    <slot name="button" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    hasAccess: {
      default: true,
      type: Boolean,
    },
    tooltip: {
      type: String,
    },
  },
};
</script>

<style scoped>
.card-small {
  font-size: 2rem;
  line-height: 1;
}

.tag {
  white-space: normal;
  min-height: 2em;
  height: auto;
}
</style>
