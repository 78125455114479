import http from './axios';

class NetworkService {
  getNetworkConfigs() {
    return http.get('/network/config');
  }

  getNetworkConfig(payload) {
    return http.get('/network/config/' + payload);
  }

  createNetworkConfig(payload) {
    return http.post('/network/config', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  deleteNetworkConfig(payload) {
    return http.delete('/network/config/' + payload);
  }

  getNetworkConfigSettings() {
    return http.get('/network/config/settings');
  }

  getNetworkConfigPeplinkGroups(id) {
    return http.get('/network/config/settings/peplink/' + id);
  }
}

export default new NetworkService();
