import http from './axios';

class AuthService {
  login(data) {
    return http.post('/auth/login', data);
  }

  googleCallback(code) {
    return http.post('/auth/google/callback', code);
  }

  register(data) {
    return http.post('/auth/register', data);
  }

  logOut() {
    return http.post('/auth/logout');
  }

  checkInvite(data) {
    return http.get('/auth/invite?token=' + data);
  }

  recoverPassword(data) {
    return http.post('/auth/password/recover', data);
  }

  resetPassword(data) {
    return http.post('/auth/password/reset', data);
  }

  googleAuth(data) {
    return http.post('/auth/google', data);
  }

  enableTwoFactor() {
    return http.post('/secure/two-factor-authentication');
  }

  confirmPassword(data) {
    return http.post('/secure/confirm-password', data);
  }

  getTwoFactorQr() {
    return http.get('/secure/two-factor-qr-code');
  }

  confirmCode() {
    return http.post('/secure/two-factor-confirm-code');
  }

  confirmTwoFactor(data) {
    return http.post('/secure/confirmed-two-factor-authentication', data);
  }

  disableTwoFactor() {
    return http.delete('/secure/two-factor-authentication');
  }

  loginUser(data) {
    return http.post('/secure/login', data);
  }

  validateChallenge(data) {
    return http.post('/secure/two-factor-challenge', data);
  }

  recoverPassword(data) {
    return http.post('/secure/password/recover', data);
  }

  resetPassword(data) {
    return http.post('/secure/password/reset', data);
  }
}

export default new AuthService();
