import http from './axios';

class PaymentService {
  createSubscriptionMethod(id) {
    return http.get('/payments/create_subscription_method/?customer=' + id);
  }

  createCaasPrice(payload) {
    return http.post('/payments/prices', payload)
  }
}

export default new PaymentService();
