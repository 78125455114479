<template>
  <BasicButton
    v-if="!iconOnly"
    color="danger"
    light
    :size="size"
    icon="trash"
  >
    {{ $t('button.delete') }}
  </BasicButton>
  <BasicButton
    v-else
    color="danger"
    light
    :size="size"
    icon="trash"
  />
</template>

<script>
export default {
  props: {
    iconOnly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
  },
};
</script>
