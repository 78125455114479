import http from './axios';

class InventoryService {
  getStats() {
    return http.get('/inventory/stats');
  }

  getInventoryLogs() {
    return http.get('/inventory/logs');
  }

  getInventory(params) {
    return http.get('/inventory', { params });
  }

  getInventoryProduct(id) {
    return http.get(`/inventory/${id}`);
  }

  createInventoryProduct(payload) {
    return http.post(`/inventory`, payload);
  }

  deleteInventoryProduct(id) {
    return http.delete(`/inventory/${id}`);
  }

  updateInventoryProduct(id, payload) {
    return http.patch(`/inventory/${id}`, payload);
  }

  addInventory(payload) {
    return http.post('/inventory/bulk', payload);
  }

  getCaasInventory() {
    return http.get('/inventory/allocated');
  }

  exportCaasHardware() {
    return http.post('/inventory/allocated/export', {
      email: 'maarten@amplr.be',
    });
  }

  exportStockValue() {
    return http.post('/inventory/export', {
      email: 'maarten@amplr.be',
    });
  }
}

export default new InventoryService();
