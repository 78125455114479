<template>
  <BasicButton outlined icon="close" :size="size">
    {{ $t('button.cancel') }}
  </BasicButton>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: 'small',
    },
  },
};
</script>
