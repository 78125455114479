<template>
  <div
    class="notification"
    :class="isSuccess ? 'is-success ' : 'is-danger'"
  >
    <p
      v-for="msg in message"
      :key="msg"
      class="has-text-white"
    >
      {{ msg }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    isSuccess: {
      default: false,
      type: Boolean,
    },
    isError: {
      default: false,
      type: Boolean,
    },
    message: {
      default: () => [],
      type: Array,
    },
  },
};
</script>
