<template>
  <div class="colums is-flex-tablet mb-6 has-border no-color">
    <section class="is-4-desktop is-6-tablet is-6-widescreen column p-0 pr-5">
      <h3 class="title is-size-4 mb-2">
        {{ title }}
      </h3>
      <p class="pb-4">
        {{ body }}
      </p>
    </section>
    <section
      class="is-8-desktop is-6-widescreen is-6-tablet column has-background-white p-5 has-border no-color"
    >
      <slot />
    </section>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
