import http from './axios';

class ProductService {
  getProducts(params) {
    return http.get('/products', { params });
  }
  getProduct(id) {
    return http.get(`/products/${id}`);
  }
  updateProduct(id, payload) {
    return http.patch(`/products/${id}`, payload);
  }
  getLocationProducts(locationId) {
    return http.get(`/products/locations/${locationId}`);
  }

  searchProducts(term) {
    return http.get(`/products/?search=${term}`);
  }

  getStripeProducts() {
    return http.get('/stripeproducts');
  }

  getConfigCosts() {
    return http.get('/products/config');
  }

  createProduct(payload) {
    return http.post('/products', payload);
  }
}

export default new ProductService();
