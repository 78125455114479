import http from './axios';

class GroupService {
  getGroups(params) {
    return http.get('/groups', { params });
  }

  getGroup(id) {
    return http.get(`/groups/${id}`);
  }

  createGroup(payload) {
    return http.post('/groups', payload);
  }

  updateGroup(id, $payload) {
    return http.put(`/groups/${id}`, $payload);
  }

  deleteGroup(id) {
    return http.delete(`/groups/${id}`);
  }

  updateGroupCustomers(payload) {
    return http.put(`/group/customers`, payload);
  }

  deleteGroupCustomers(id) {
    return http.delete(`/group/customer/${id}`);
  }

  getLocationOpeninghours(id) {
    return http.get(`/groups/${id}/openinghours`);
  }

  updateLocationOpeninghours(id, data) {
    return http.put(`/groups/${id}/openinghours`, data);
  }

  getPartners(id) {
    return http.get(`/groups/${id}/partner`);
  }

  addPartner(customer_id, partner_id) {
    return http.post(`/groups/${customer_id}/partner/${partner_id}`);
  }

  deletePartner(customer_id, partner_id) {
    return http.delete(`/groups/${customer_id}/partner/${partner_id}`);
  }
}

export default new GroupService();
