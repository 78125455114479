<template>
  <BasicButton
    v-if="!iconOnly"
    color="primary"
    light
    size="small"
    icon="pencil"
  >
    {{ $t('button.edit') }}
  </BasicButton>
  <BasicButton
    v-else
    color="primary"
    light
    size="small"
    icon="pencil"
  />
</template>

<script>
export default {
  props: {
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
