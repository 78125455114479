import http from './axios';

class LocationService {
  getLocations(params) {
    return http.get('/locations', { params });
  }

  getLocationsPositions() {
    return http.get('/locations/map');
  }

  getLocationsWithIssues(page) {
    return http.get(`/locations?systemStatus[whereInRel]=0,2&page=${page}`);
  }

  getLocation(id) {
    return http.get(`/locations/${id}`);
  }

  updateLocation(id, data) {
    return http.put(`/locations/${id}`, data);
  }

  rebootNetwork(id) {
    return http.post(`/locations/${id}/network/commands/reboot`);
  }

  deleteLocation(id) {
    return http.delete(`/locations/${id}`);
  }

  restoreLocation(id) {
    return http.put(`/locations/${id}/restore`);
  }

  getLocationQr(id) {
    return http.get(`/locations/${id}/qr`, { responseType: 'arraybuffer' });
  }

  getLocationOpeninghours(id) {
    return http.get(`/locations/${id}/openinghours`);
  }

  updateLocationOpeninghours(id, data) {
    return http.put(`/locations/${id}/openinghours`, data);
  }

  printZerotierLabel(id) {
    return http.post(`/print/zerotier/${id}`);
  }

  printLocationPublicLabel(id) {
    return http.post(`/locations/${id}/print/public`);
  }

  printLocationLabel(id, data) {
    return http.post(`/locations/${id}/print/label`, data);
  }

  status(id) {
    return http.get(`/locations/${id}/network`);
  }

  wlans(id) {
    return http.get(`/locations/${id}/network/wlans`);
  }

  updateWlan(id, wlanId, payload) {
    return http.patch(`/locations/${id}/network/wlans/${wlanId}`, payload);
  }

  batchUpdateWlans(id, payload) {
    return http.patch(`/locations/${id}/network/wlans`, payload);
  }

  devices(id) {
    return http.get(`/locations/${id}/network/devices`);
  }

  deviceDetails(id, mac, params) {
    return http.get(`/locations/${id}/network/devices/${mac}`, { params });
  }

  updateDeviceDetails(id, mac, payload, params) {
    return http.patch(`/locations/${id}/network/devices/${mac}`, payload, {
      params: params,
    });
  }

  rebootDevice(id, mac, params) {
    return http.post(`/locations/${id}/network/devices/${mac}/reboot`, params);
  }

  clients(id, params) {
    return http.get(`/locations/${id}/network/clients`, { params: params });
  }

  updateClient(id, deviceId, data) {
    return http.patch(`/locations/${id}/network/clients/${deviceId}`, data);
  }

  getPublicStatus(public_id) {
    return http.get(`/public/status/${public_id}`);
  }

  getPublicDeviceGroups(public_id) {
    return http.get(`/public/status/${public_id}/devicegroups`);
  }

  getHistory(siteId) {
    return http.get('/locations/' + siteId + '/log');
  }
  getDeviceGroups(id) {
    return http.get(`/locations/${id}/network/devicegroups`);
  }
  getDeviceGroup(id, deviceGroupId) {
    return http.get(`/locations/${id}/network/devicegroups/${deviceGroupId}`);
  }
  createDeviceGroup(id, data) {
    return http.post(`/locations/${id}/network/devicegroups`, data);
  }

  editDeviceGroup(id, deviceGroupId, data) {
    return http.patch(
      `/locations/${id}/network/devicegroups/${deviceGroupId}`,
      data
    );
  }

  deleteDeviceGroup(id, deviceGroupId) {
    return http.delete(
      `/locations/${id}/network/devicegroups/${deviceGroupId}`
    );
  }

  createInstallationLog(id, payload) {
    return http.post(`/locations/${id}/installation/`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  deleteImageFromLog(id, logId, payload) {
    return http.patch(
      `/locations/${id}/installation/${logId}/image`,
      payload,
      {}
    );
  }

  getStatusButtons(type, id) {
    return http.get(`/locations/status_buttons?type=${type}&id=${id}`);
  }

  updateStatusButtons(payload) {
    return http.post(`/locations/status_buttons`, payload);
  }

  exportInstallationLog(id) {
    return http.get(`/locations/${id}/installation/export`, {
      responseType: 'blob',
    });
  }

  getPpsk(id) {
    return http.get(`/locations/${id}/network/ppsk`);
  }

  createPpsk(id, data) {
    return http.post(`/locations/${id}/network/ppsk`, data);
  }

  deletePpsk(id, profileId) {
    return http.delete(`/locations/${id}/network/ppsk/${profileId}`);
  }

  createConfigurationLog(id, payload) {
    return http.post(`/locations/${id}/configuration`, payload);
  }

  exportConfigurationLog(id) {
    return http.get(`/locations/${id}/configuration/export`, {
      responseType: 'blob',
    });
  }

  createLocationComment(id, payload) {
    return http.post(`/locations/${id}/report`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  updateLocationComment(location_id, report_id, payload) {
    return http.post(`/locations/${location_id}/report/${report_id}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  deleteLocationComment(location_id, report_id) {
    return http.delete(`/locations/${location_id}/report/${report_id}`);
  }

  exportLocationCommentd(location_id) {
    return http.get(`/locations/${location_id}/report/export`, {
      responseType: 'blob',
    });
  }

  getVlanConfig(location_id) {
    return http.get(`/locations/${location_id}/network/settings/subnets`);
  }

  getLanProfiles(location_id) {
    return http.get(`/locations/${location_id}/network/settings/profiles`);
  }

  updatePort(id, mac, params) {
    return http.post(`/locations/${id}/network/devices/${mac}/port`, params);
  }

  getLocationSubscription(id, subId) {
    return http.get(`/locations/${id}/subscriptions/${subId}`);
  }

  updateLocationSubscription(id, subId, data) {
    return http.patch(`/locations/${id}/subscriptions/${subId}`, data);
  }

  getCaasHardware(id) {
    return http.get(`/locations/${id}/hardware`);
  }

  returnCaasHardware(id, payload) {
    return http.patch(`/locations/${id}/hardware`, payload);
  }
}

export default new LocationService();
