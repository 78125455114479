<template>
  {{ breadcrumbs }}
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li v-for="(crumb, index) in breadcrumbs" :key="index">
        <router-link :to="crumb.path">{{ crumb.label }}</router-link>
        <!-- <span style="padding: 0 0.75rem" v-else>{{ crumb.meta.crumb }}</span> -->
      </li>
    </ul>
  </nav>
</template>
<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'BreadCrumbs',

  setup() {
    const route = useRoute();

    const breadcrumbs = computed(() => {
      let breadcrumbTrail = [];

      route.matched.forEach((routeMatch) => {
        console.log(routeMatch);
        if (routeMatch.meta && routeMatch.meta.crumb) {
          const crumbLabel =
            typeof routeMatch.meta.crumb === 'function'
              ? routeMatch.meta.crumb(route)
              : routeMatch.meta.crumb;
          console.log(crumbLabel);
          breadcrumbTrail.push({
            path: routeMatch.path,
            label: crumbLabel,
          });
        }
      });

      return breadcrumbTrail;
    });

    return { breadcrumbs };
  },
};
</script>
