<template>
  <BasicNotification color="danger" light icon="circle-info" iconSmall>{{
    error
  }}</BasicNotification>
</template>
<script>
export default {
  props: {
    error: {
      type: String,
      required: true,
    },
  },
};
</script>
