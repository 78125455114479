<template>
  <Popper arrow hover placement="right" z-index="9999">
    <font-awesome-icon
      :icon="icon ? ['fad', icon] : ['fad', 'circle-info']"
      class="has-text-link is-clickable"
    />

    <template #content>
      <div
        style="
          max-width: 200px;
          font-size: 12px;
          white-space: normal !important;
        "
      >
        {{ content }}
      </div>
    </template>
  </Popper>
</template>
<script>
import Popper from 'vue3-popper';

export default {
  components: {
    Popper,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
};
</script>

<style>
:root {
  --popper-theme-background-color: rgb(72, 95, 199);
  --popper-theme-background-color-hover: rgb(72, 95, 199);
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 20px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
