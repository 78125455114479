<template>
  <div class="box">
    <div v-if="loading">
      <div class="is-flex is-justify-content-space-between">
        <div class="is-flex">
          <h2 class="title is-size-5 mb-4" :class="tooltip ? 'mr-2' : ''">
            {{ title }}
          </h2>
          <BasicTooltip v-if="tooltip" :content="tooltip" />
        </div>
      </div>
      <slot />
    </div>
    <BasicLoader v-else />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
};
</script>
